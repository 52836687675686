import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import classNames from "classnames";
import { useRouter } from "next/router";

import { getStorage } from "@hooks/use-persistent-state";
import useSnackbar from "@hooks/use-snackbar";

import { Button } from "@components/Button";
import ErrorField from "@components/Client/ErrorField";
import SubmitButton from "@components/Client/SubmitButton";
import { FieldRow } from "@components/ContactInfoForm";
import CouponInput from "@components/Coupons/CouponInput";
import InfoIcon from "@components/Icons/InfoIcon";
import MailIcon from "@components/Icons/MailIcon";
import { ScheduledPaymentInfo } from "@components/Package/PackagePaymentOptionForm";
import PaymentInput from "@components/PaymentInput";

import { StepWithElementProps, withStripeElement } from "./withStripeElement";

interface PaymentMethodFormProps {
  isLoading: boolean;
  setError: Dispatch<SetStateAction<null | string>>;
  error: string | null;
  clientSecret: string;
  returnUrl: string;
  clientId: string;
  showScheduledPaymentInfo?: boolean;
  userId?: string;
  showCouponInput?: boolean;
  usagePaymentInfo?: string;
}

const PaymentMethodForm = withStripeElement<PaymentMethodFormProps>(
  ({
    isLoading,
    setError,
    error,
    returnUrl,
    clientId,
    showScheduledPaymentInfo = false,
    userId,
    showCouponInput = false,
    usagePaymentInfo,
  }) => {
    const [isLocalLoading, setIsLocalLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const onSubmitForm = async (e: FormEvent) => {
      e.preventDefault();
      if (!stripe || !elements) {
        return;
      }
      setIsLocalLoading(true);
      setError(null);

      try {
        const { error } = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: `${returnUrl}${
              returnUrl.includes("?") ? "&" : "?"
            }clientId=${clientId}`,
          },
        });
        if (error) {
          throw new Error((error?.message || error) as string);
        }
      } catch (error) {
        setError(
          `Error with payment method confirmation: ${error?.message || error}`
        );
      } finally {
        setIsLocalLoading(false);
      }
    };

    return (
      <form className="w-full flex flex-col">
        <FieldRow className="!mb-2" label="Payment method details">
          <PaymentInput
            className={classNames(
              "mt-1 form-input block w-full py-2.5 px-4 text-xl border  rounded-lg focus:outline-none focus:shadow-none  transition duration-150 ease-in-ou",
              // TODO Iframe provided by stripe, style properly in the future
              "border-grey-900 dark:bg-grey-950 hover:bg-grey-950 hover:border-grey-950 focus:bg-white  "
            )}
          />
        </FieldRow>
        {error && <ErrorField>{error}</ErrorField>}
        {showCouponInput && <CouponInput userId={userId as string} />}
        <div className="flex text-foreground bg-foreground/7 rounded-lg items-center py-3 px-4 leading-5 text-xs gap-4">
          <MailIcon className="flex-none" />
          <p>
            Once the payment is complete, you&apos;ll receive a receipt via
            email.
          </p>
        </div>
        {!!usagePaymentInfo && (
          <div className="my-2 flex text-foreground bg-foreground/7 rounded-lg items-center py-3 px-4 leading-5 text-xs gap-4">
            <InfoIcon className="flex-none" />
            <p>{usagePaymentInfo}</p>
          </div>
        )}
        {showScheduledPaymentInfo && <ScheduledPaymentInfo />}
        <div className="mt-6 text-center flex justify-center">
          <SubmitButton
            actionTitle="Confirm payment"
            onAction={onSubmitForm}
            isLoading={isLoading || isLocalLoading}
            dataHeapEventName="product_client_submits_payment_method"
          />
        </div>
      </form>
    );
  }
);

interface PaymentMethodValidationProps {
  clientSecret: string;
  onValidate: (success: boolean, paymentMethod?: string) => void;
  error?: string;
  errorButtonLabel?: string;
  onClickButtonError?: () => void;
}

const PaymentMethodValidation = withStripeElement<PaymentMethodValidationProps>(
  ({
    clientSecret,
    onValidate,
    errorButtonLabel = "Try again",
    onClickButtonError,
    error: errorProp,
  }) => {
    const stripe = useStripe();
    const snackBar = useSnackbar();
    const [localError, setLocalError] = useState<null | string>(null);
    const [isLoading, setIsLoading] = useState(true);

    const error = useMemo(
      () => errorProp || localError,
      [errorProp, localError]
    );

    useEffect(() => {
      const beforeunload = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        return (e.returnValue =
          "Your payment method has been confirmed and we are processing your payment. Do not close or refresh this page.");
      };
      window.addEventListener("beforeunload", beforeunload);

      return () => {
        window.removeEventListener("beforeunload", beforeunload);
      };
    }, []);

    useEffect(() => {
      if (!stripe || !clientSecret) {
        return;
      }

      const validateSetupIntent = async () => {
        const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);
        const storage = getStorage("sessionStorage");

        switch (setupIntent?.status) {
          case "succeeded":
            const key = `setup_intent_${setupIntent.id}`;
            if (storage.getItem(key)) {
              // we should not validate twice same payment intent
              snackBar.showMessage(
                "You payment method was previously confirmed!",
                ""
              );
              onValidate(false);
            } else {
              snackBar.showMessage(
                "Success!",
                "Your payment method has been confirmed and we are processing your payment. Do not close or refresh this page."
              );
              onValidate(
                true,
                setupIntent?.payment_method as unknown as string
              );
              storage.setItem(key, "true");
            }
            break;

          case "processing":
            snackBar.showMessage(
              "Success!",
              "Processing payment details. We'll update you when processing is complete."
            );
            setIsLoading(false);
            break;

          case "requires_payment_method":
            setLocalError(
              "Failed to process payment details. Please try another payment method."
            );
            setIsLoading(false);
            onValidate(false);
            break;
        }
      };
      validateSetupIntent();
    }, [stripe, clientSecret]);
    return (
      <div className="w-full flex flex-col">
        {error && <ErrorField>{error}</ErrorField>}
        <div className="mt-12 text-center flex justify-center">
          <SubmitButton
            actionTitle={errorButtonLabel}
            onAction={() => onClickButtonError && onClickButtonError()}
            errors={isLoading}
            isLoading={isLoading && !error}
            data-heap-event-name="client_retries_payment_method"
          />
        </div>
      </div>
    );
  }
);

type PaymentRequestFormProps = {
  userId: string;
  clientIdOrEmail: string;
  firstName?: string;
  lastName?: string;
  returnUrl: string;
  onValidate: (success: boolean, paymentMethod?: string) => void;
  isLoading: boolean;
  showScheduledPaymentInfo?: boolean;
  showCouponInput?: boolean;
  error?: string;
  errorButtonLabel?: string;
  onClickButtonError?: () => void;
  usagePaymentInfo?: string;
} & Omit<StepWithElementProps, "clientSecret">;

export const PaymentRequestForm: React.FC<PaymentRequestFormProps> = ({
  userId,
  clientIdOrEmail,
  firstName,
  lastName,
  returnUrl,
  stripePublicKey,
  connectedAccountId,
  onValidate,
  isLoading,
  showScheduledPaymentInfo = false,
  showCouponInput,
  error: errorProp,
  errorButtonLabel,
  onClickButtonError,
  usagePaymentInfo,
}) => {
  const router = useRouter();
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(
    (router?.query?.setup_intent_client_secret as unknown as string) ||
      undefined
  );
  const [clientId, setClientId] = useState("");
  const [error, setError] = useState(null);
  const setupIntentId =
    (router?.query?.setup_intent as unknown as string) || undefined;

  useEffect(() => {
    if (!clientIdOrEmail || clientSecret) {
      return;
    }

    const createSetupIntent = async () => {
      try {
        setIsLocalLoading(true);
        const response = await axios.post(
          `/api/v1/users/${userId}/clients/${clientIdOrEmail}/stripe/setup-intent`,
          {
            ...(firstName && { firstName }),
            ...(lastName && { lastName }),
          }
        );
        setClientSecret(response.data.setupIntent.clientSecret);
        setClientId(response.data.clientId);
      } finally {
        setIsLocalLoading(false);
      }
    };

    createSetupIntent();
  }, [clientIdOrEmail]);

  if (!clientSecret) {
    return <Button className="w-full !py-6" isLoading={true} />;
  }

  return !setupIntentId ? (
    <PaymentMethodForm
      connectedAccountId={connectedAccountId}
      stripePublicKey={stripePublicKey}
      clientSecret={clientSecret}
      error={error}
      isLoading={isLocalLoading || isLoading}
      returnUrl={returnUrl}
      setError={setError}
      clientId={clientId}
      showScheduledPaymentInfo={showScheduledPaymentInfo}
      userId={userId}
      showCouponInput={showCouponInput}
      usagePaymentInfo={usagePaymentInfo}
    />
  ) : (
    <PaymentMethodValidation
      clientSecret={clientSecret}
      connectedAccountId={connectedAccountId}
      stripePublicKey={stripePublicKey}
      onValidate={onValidate}
      error={errorProp}
      errorButtonLabel={errorButtonLabel}
      onClickButtonError={onClickButtonError}
    />
  );
};
