import { Button } from "@components/Button";
import CreditCardIcon from "@components/Icons/CreditCardIcon";

import { SubscriptionInformation, TodaysCharge } from "./ChargeInfo";
import IconWithDetails from "./IconWithDetails";
import SafePaymentTooltip from "./SafePaymentTooltip";
import { PriceItemType } from "./types";
import UnfoldingDetails from "./UnfoldingDetails";

interface PriceDetailsProps {
  totalHeader: string;
  taxHeader: string;
  subtotal: string;
  taxTotal: string;
  items: PriceItemType[];
  hasPaid?: boolean;
  disableFold?: boolean;
  invoiceUrl?: string;
  invoiceNumber?: string;
  invoiceDownload?: string;
  coupon?: { value: number; unit: string };
  discount?: string;
  headerWithRecurring: string;
  subtotalWithTax?: string;
  oneTimeDiscount?: boolean;
  paymentCount?: number;
  hideIconDetails?: boolean;
  appliedBalance?: string;
  depositBalance?: string;
}

const PriceDetails: React.FC<PriceDetailsProps> = ({
  taxHeader,
  subtotal,
  items,
  hasPaid = false,
  disableFold = false,
  taxTotal,
  totalHeader,
  invoiceNumber,
  invoiceUrl,
  invoiceDownload,
  coupon,
  discount,
  headerWithRecurring,
  subtotalWithTax,
  oneTimeDiscount,
  paymentCount,
  hideIconDetails = false,
  appliedBalance,
  depositBalance,
}) => {
  const couponIsPercent = coupon?.unit === "%";
  const couponDiscount = couponIsPercent
    ? coupon.value + coupon.unit
    : discount;

  const itemAndTotalLine = (left: string, right: string) => (
    <div className="flex w-full justify-between">
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );

  const title = (
    <div className="flex gap-1">
      {coupon && !oneTimeDiscount && (
        <div className="line-through text-foreground/50 font-normal">
          {subtotalWithTax}
        </div>
      )}
      <div>{headerWithRecurring}</div>
    </div>
  );

  const subtitle = (
    <div className="flex gap-1">
      <div>Taxes included.</div>
      {coupon && couponDiscount && (
        <div className="text-white bg-blue-350 flex items-center justify-center px-1.5 rounded-md text-xs">{`${couponDiscount} off`}</div>
      )}
    </div>
  );

  const priceInfo = (
    <div className="w-full">
      <div className="bg-foreground/7 text-foreground rounded-xl p-4 w-full divide-y divide-foreground/20 text-sm mb-2">
        <div>
          {items.map((item, index) => (
            <div key={`${item.header}-${index}`} className="pb-2">
              {itemAndTotalLine(item.header, item.price)}
            </div>
          ))}
          {depositBalance && (
            <div className="pb-2">
              {itemAndTotalLine("Deposit balance", `-${depositBalance}`)}
            </div>
          )}
          <div className="font-medium">
            {itemAndTotalLine("Subtotal", subtotal)}
          </div>
        </div>
        {taxHeader && (
          <div className="text-foreground/50 mt-3 pt-3">
            {itemAndTotalLine(taxHeader, taxTotal)}
          </div>
        )}
        {coupon && (
          <div className="text-foreground/50 mt-3 pt-3">
            {itemAndTotalLine(
              `Applied ${oneTimeDiscount ? "one-time" : ""} promo code`,
              `-${discount}`
            )}
          </div>
        )}
        {appliedBalance && (
          <div className="text-foreground/50 mt-3 pt-3">
            {itemAndTotalLine("Applied balance", `-${appliedBalance}`)}
          </div>
        )}
        <div className="text-xl mt-3 pt-3 font-medium">
          {itemAndTotalLine(
            hasPaid ? "Total paid" : "Total Payment",
            headerWithRecurring
          )}
        </div>
      </div>
      {!hasPaid ? (
        <div className="mt-2 mb-3 space-y-2">
          <TodaysCharge total={totalHeader} />
          {paymentCount && <SubscriptionInformation count={paymentCount} />}
        </div>
      ) : (
        <>
          {invoiceDownload && (
            <Button primary className="w-full rounded-xl">
              <a href={invoiceDownload} target="blank">
                Download invoice
              </a>
            </Button>
          )}
          {invoiceNumber && (
            <div className="text-foreground/50 underline text-center text-sm my-3">
              <a href={invoiceUrl} target="blank">
                Invoice {invoiceNumber}
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );

  return (
    <div>
      {!hideIconDetails && (
        <IconWithDetails
          icon={<CreditCardIcon className="" />}
          title={title}
          subtitle={subtitle}
          rightElement={<SafePaymentTooltip />}
        />
      )}
      {disableFold ? (
        priceInfo
      ) : (
        <UnfoldingDetails heapName="client_viewed_price_details">
          {priceInfo}
        </UnfoldingDetails>
      )}
    </div>
  );
};

export default PriceDetails;
